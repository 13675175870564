<template>
  <div>
    <b-table
      striped
      hover
      :items="comomunityList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(image)="{ item }">
        <img :src="item.image" class="image__table" alt="" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-community"
      centered
      :title="editIdModal != null ? 'Edit Community' : 'Add Community'"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label>Name:</label>
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                placeholder="Name"
                v-model="formPayloadCommunity.name"
              />
              <small
                v-for="(validation, index) in validationsForm.name"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
        </div>

        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save Community
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Community
        </b-button>
      </ValidationObserver>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCol,
  BRow,
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormFile,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BCol,
    BRow,
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    communityData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    communityData(value) {
      this.comomunityList = value;
    },
    formPayload(value) {
      this.formPayloadCommunity = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
  },
  data() {
    return {
      required,
      moment,
      isLoadingComponent: false,
      currentPageList: 1,
      resultData: {},
      comomunityList: [],
      editIdModal: null,
      formPayloadCommunity: {
        name: "",
      },
      validationsForm: "",
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.comomunityList.length;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
